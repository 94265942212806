var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"examine-list"},[_c('div',{staticClass:"header-box"},[_vm._m(0),_c('el-button',{on:{"click":function($event){return _vm.detail()}}},[_vm._v("审题统计")])],1),(_vm.tableData.length)?[_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"item-title"},[_vm._v(_vm._s(item.examName))]),_c('div',{staticClass:"content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":item.examPaperExamineVOS,"border":""}},[_c('el-table-column',{attrs:{"prop":"subjectName","label":"科目","align":"center","width":"200px"}}),_c('el-table-column',{attrs:{"prop":"cutOffTime","label":"审核限时","align":"center","width":"340px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"time-box"},[_c('el-tooltip',{attrs:{"effect":"dark","content":_vm.getTipsCnt(row),"placement":"top"}},[_c('div',{staticClass:"tips-time-box",class:{
                      'tips-on': row.status == 1,
                      'tips-on-suss': row.status == 2,
                    }})]),(row.trainingTimeEffective == 0)?[_vm._v("不限时")]:[_vm._v(_vm._s(_vm.getDeadline(row)))]],2)]}}],null,true)}),_c('el-table-column',{attrs:{"label":"审核进度","align":"center","width":"351px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"progress-box"},[_c('div',{staticClass:"suss-box",style:({
                    width: (row.examineNum / row.totalNum) * 100 + '%',
                  })}),_c('span',{staticClass:"tips"},[_vm._v(" "+_vm._s(row.examineNum + "/" + row.totalNum)+" ")])])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"teacherNames","label":"审核教师","align":"center","show-overflow-tooltip":""}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.status == 2 || row.status == 3)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.examine(row)}}},[_vm._v("查看记录")]):_vm._e(),(row.status == 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.examine(row)}}},[_vm._v(" 前往审核 ")]):_vm._e(),(row.status == 0)?_c('el-tooltip',{attrs:{"effect":"dark","content":"不在审核时间内","placement":"top"}},[_c('span',{staticStyle:{"color":"#d5d5d5","user-select":"none"}},[_vm._v(" 前往审核 ")])]):_vm._e()]}}],null,true)})],1)],1)])}),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.search.current,"page-sizes":[10, 20, 50, 100, 200, 500, 1000],"page-size":_vm.search.size,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)]:_c('div',{staticClass:"no-data"},[_c('no-data',{attrs:{"message":"暂无审题任务"}})],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-box-title"},[_c('div',{staticClass:"title"},[_vm._v("审核列表")]),_c('span',{staticClass:"tips"},[_c('i',{staticClass:"el-icon-warning"}),_vm._v(" 请在审核时限内完成任务。若逾期未审，则待审题目将自动设为审核通过！ ")])])
}]

export { render, staticRenderFns }