<template>
  <div v-loading="loading" class="examine-list">
    <div class="header-box">
      <div class="header-box-title">
        <div class="title">审核列表</div>
        <span class="tips">
          <i class="el-icon-warning"></i>
          请在审核时限内完成任务。若逾期未审，则待审题目将自动设为审核通过！
        </span>
      </div>
      <el-button @click="detail()">审题统计</el-button>
    </div>
    <template v-if="tableData.length">
      <div v-for="(item, index) in tableData" :key="index">
        <div class="item-title">{{ item.examName }}</div>
        <div class="content">
          <el-table :data="item.examPaperExamineVOS" border style="width: 100%">
            <el-table-column
              prop="subjectName"
              label="科目"
              align="center"
              width="200px"
            >
            </el-table-column>
            <el-table-column
              prop="cutOffTime"
              label="审核限时"
              align="center"
              width="340px"
            >
              <template slot-scope="{ row }">
                <div class="time-box">
                  <el-tooltip
                    effect="dark"
                    :content="getTipsCnt(row)"
                    placement="top"
                  >
                    <div
                      class="tips-time-box"
                      :class="{
                        'tips-on': row.status == 1,
                        'tips-on-suss': row.status == 2,
                      }"
                    ></div>
                  </el-tooltip>
                  <template v-if="row.trainingTimeEffective == 0"
                    >不限时</template
                  >
                  <template v-else>{{ getDeadline(row) }}</template>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="审核进度" align="center" width="351px">
              <template slot-scope="{ row }">
                <div class="progress-box">
                  <div
                    class="suss-box"
                    :style="{
                      width: (row.examineNum / row.totalNum) * 100 + '%',
                    }"
                  ></div>
                  <span class="tips">
                    {{ row.examineNum + "/" + row.totalNum }}
                  </span>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="teacherNames"
              label="审核教师"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
              <template slot-scope="{ row }">
                <!--               -->
                <el-button
                  v-if="row.status == 2 || row.status == 3"
                  type="text"
                  @click="examine(row)"
                  >查看记录</el-button
                >
                <el-button
                  v-if="row.status == 1"
                  type="text"
                  @click="examine(row)"
                >
                  前往审核
                </el-button>
                <el-tooltip
                  v-if="row.status == 0"
                  effect="dark"
                  content="不在审核时间内"
                  placement="top"
                >
                  <span style="color: #d5d5d5; user-select: none">
                    前往审核
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :current-page="search.current"
          :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
          :page-size="search.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </template>
    <div v-else class="no-data">
      <no-data message="暂无审题任务"></no-data>
    </div>
  </div>
</template>
<script>
import { getSubjectList } from "@/core/util/util";
import { getExamineList } from "@/core/api/exam/exampaper";
import moment from "moment";
import { examTypeOptions } from "@/core/util/constdata";
export default {
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    const subjectList = getSubjectList();
    return {
      search: {
        current: 1,
        size: 40,
      },
      yearOptions: yearOptions.reverse(),
      typeOptions: [...examTypeOptions],
      subjectList: subjectList,
      total: 0,
      tableData: [],
      loading: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTipsCnt(v) {
      let array = ["尚未开始", "正在审核", "审核完成", "审核关闭"];
      return array[v.status];
    },
    getDeadline(row) {
      const trainingTimeEnd = new Date(row.trainingTimeEnd).getTime();
      const now = new Date().getTime();

      if (trainingTimeEnd > now) {
        return (
          moment(row.trainingTimeBegin).format("yyyy-MM-DD HH:mm") +
          " ~ " +
          moment(row.trainingTimeEnd).format("yyyy-MM-DD HH:mm")
        );
      } else {
        return `${moment(row.trainingTimeEnd).format(
          "yyyy-MM-DD HH:mm"
        )}（已截止）`;
      }
    },
    examine(row) {
      this.$router.push({
        name: "examvariation",
        query: {
          examId: row.examId,
          id: row.examPaperId,
          fromName: "/examine/list",
          subjectId: row.subjectId || 2,
        },
      });
    },
    detail() {
      this.$router.push({
        path: "/examine/auditStatistics",
      });
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    async getList() {
      this.loading = true;
      const res = await getExamineList({ ...this.search });

      this.tableData = res.data.data.records.map((item) => {
        return {
          show: true,
          ...item,
        };
      });
      this.total = res.data.data.total;
      this.loading = false;
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getList();
    },
    getLevelName(val) {
      if (val == 2) {
        return "初";
      } else if (val == 3) {
        return "高";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.examine-list {
  padding: 18px;
  margin-top: 24px;
  background-color: #ffffff;
  .tips-time-box {
    width: 10px;
    height: 10px;
    background: #dee0e7;
    border-radius: 50%;
    margin-right: 6px;
  }
  .tips-on {
    background: #f19c45;
  }
  .tips-on-suss {
    background: #2474ed;
  }
  .time-box {
    display: flex;
    align-items: center;
  }
  .progress-box {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dee0e7;
    overflow: hidden;
    text-align: left;
    padding-left: 8px;
    height: 16px;
    line-height: 16px;
    position: relative;
    .tips {
      font-size: 12px;
      z-index: 10px;
      position: absolute;
    }
    .suss-box {
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      position: absolute;
      background: #cbe0ff;
      left: 0;
      top: 0;
      height: 16px;
      line-height: 16px;
    }
  }

  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    .header-box-title {
      display: flex;
      align-items: center;
    }
    i {
      color: #2474ed;
    }
    .tips {
      font-size: 14px;
      color: #5e6a7a;
    }
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #0a1119;
    margin-right: 18px;
  }
  .item-title {
    font-size: 14px;
    line-height: 14px;
    border: 1px solid #dee0e7;
    border-bottom: 0;
    padding: 12px 18px;
  }
  .content {
    margin-bottom: 18px;
    .item {
      background-color: #fff;
      box-sizing: border-box;
      padding: 24px;
      margin-bottom: 10px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .title {
          display: flex;
          align-items: center;
          color: #101011;
          font-size: 14px;
          font-weight: bold;

          .level {
            font-size: 14px;
            color: #fff;
            box-sizing: border-box;
            padding: 4px;
            line-height: 1;
            border-radius: 2px;
            background: #2474ed;
            margin-right: 8px;
            font-weight: 500;
          }

          .type {
            background-color: #fff;
            font-size: 14px;
            color: #04c391;
            box-sizing: border-box;
            padding: 4px;
            border: 1px solid #04c391;
            line-height: 1;
            border-radius: 2px;
            font-weight: 500;
            margin-right: 8px;
          }
        }

        .switch {
          color: #2474ed;
          cursor: pointer;

          i {
            margin-left: 9px;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin: 24px 0;
    }
  }

  .no-data {
    background-color: #fff;
    padding: 24px 48px;
  }
}
</style>
